import React from "react";
import Image from "./image";

const TeamMember = ({
  imageFileName,
  imageAlt,
  header,
  subheader
}) => {
  return (
    <div className="single-team mb--30">
      <div className="team-imgae">
        <Image fileName={imageFileName} alt={imageAlt}></Image>
      </div>
      <div className="team-info">
        <h3>{header}</h3>
        <p>{subheader}</p>
      </div>
    </div>
  );
};

export default TeamMember;
