import React from "react";
import "./top.scss";
import Image from "../../components/image";
import { Link } from "react-scroll";

const Top = () => {
  return (
    <div className="hero-slider hero-slider-4" id="slider">
      <div className="single-slide">
        <div className="hero-content-one container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="slider-text-info">
                <h1>
                  Great software is built by amazing teams
                </h1>
                <h4>We are VA Software Solutions and this is what we do</h4>
                <div className="slider-button">
                  <Link
                     className="slider-btn theme-btn"
                      activeClass="active"
                      to="Services"
                      spy={true}
                      smooth="easeInOutQuart"
                    >
                      Read more
                    </Link>
                    <Link
                     className="slider-btn white-btn"
                      activeClass="active"
                      to="Contact"
                      spy={true}
                      smooth="easeInOutQuart"
                    > 
                      Contact us
                    </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="slider-img">
                {/* <Image fileName={imageFileName} alt="slider"></Image> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Top;
