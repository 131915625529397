import React from "react";
import Slider from "react-slick";
import { useStaticQuery, graphql, Link } from "gatsby";

const Position = ({ frontmatter }) => {
  const { anchor, header, subheader } = frontmatter;
  const files = useStaticQuery(graphql`
  {
    careers: allMarkdownRemark(
      sort: { fields: [frontmatter___title], order: ASC }
      filter: {fileAbsolutePath: {regex: "/(/careers/)/" }}
      ) {
      nodes {
        frontmatter {
          title
        }
        parent {
          ... on File {
            name
          }
        }
      }
    }
  } 
`);

const settings = {
    arrows: true,
    autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScoll: 1,
    centerMode: false,
    centerPadding: "10px",
    prevArrow: false,
    nextArrow: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 479,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  return (
    <div className="section-ptb" id={anchor}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 ml-auto mr-auto">
            <div className="section-title">
              <h2>{anchor} <span>- {header}</span></h2>
              <p>{subheader}</p>
            </div>
          </div>
        </div>

        <div className="row career-active center-career">
          <Slider {...settings}>
            {files.careers.nodes.map((post, index) => (
              <div className="career-wrapper">
                <div className="career career-bg" key={index}>
                  <div className="career-title">
                    <h4>{post.frontmatter.title}</h4>
                  </div>
                  <div className="career-list">
                    <p>{`/careers/${post.parent.name}`}</p>
                  </div>
                  <div className="career-btn">
                    <Link to={`/careers/${post.parent.name}`} className="button" smooth="easeInOutQuart">More info</Link>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>

        <div className="row browse-careers-section">
          <div className="col-lg-6 ml-auto mr-auto">
            <div className="section-title">
              <h2>
                <span>
                <Link to="/careers" className="button" smooth="easeInOutQuart">Browse all open positions</Link>
              </span>
              </h2>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Position;
