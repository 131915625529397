import React from "react";
import { Collapse } from "react-collapse";
import Image from "../../../components/image";

const FAQ = ({ frontmatter }) => {
  const [isCheckboxCollapseOpen, setIsCheckboxCollapseOpen] = React.useState([true]);

  const handleClick = React.useCallback(
    (index) => {
      let initial = [...isCheckboxCollapseOpen];
      initial[index] = !initial[index];
      setIsCheckboxCollapseOpen(initial);
    },
    [isCheckboxCollapseOpen]
  );

  if (!frontmatter) {
    return null;
  }

  const { anchor, header, subheader, imageFileName, faq } = frontmatter;

  return (
    <div className="section-ptb" id={anchor}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 ml-auto mr-auto">
            <div className="section-title">
              <h2>
                {anchor.charAt(0).toUpperCase() + anchor.slice(1).toLowerCase()} <span>- {header} </span>
              </h2>
              <p>{subheader}</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 order-2 order-lg-1">
            <div className="question-content-wrap">
              <div className="faequently-area-inner">
                <div className="faequently-accordion">
                  {faq.map(({ question, answer }, index) => (
                    <div key={index}>
                      <h4
                        className={isCheckboxCollapseOpen[index] ? "open" : null}
                        onClick={() => handleClick(index)}
                      >
                        {question}
                      </h4>
                      <Collapse isOpened={isCheckboxCollapseOpen[index]}>
                        <div className="faequently-description">
                          <p>{answer}</p>
                        </div>
                      </Collapse>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2">
            <div className="question-image">
              <Image fileName={imageFileName} alt={imageFileName} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
