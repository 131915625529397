import React from "react";
import TeamMember from "../../../components/teamMember";

const Team = ({ frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, subheader: rootSubHeader, teamMember } = frontmatter;

  return (
    <div className="bg-grey section-ptb" id={anchor}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 ml-auto mr-auto">
            <div className="section-title">
              <h2>
                Team <span>- {rootHeader}</span>
              </h2>
              <p>{rootSubHeader}</p>
            </div>
          </div>
        </div>
        <div className="row">
          {teamMember.map(({ ...tmProps }, index) => (
            <div className="col-lg-3 col-md-6" key={index}>
              <TeamMember {...tmProps} key={index}></TeamMember>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
