import React from "react";

const Services = ({ frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header, subheader, services } = frontmatter;

  return (
    <div className="section-ptb" id={anchor}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 ml-auto mr-auto">
            <div className="section-title">
              <h2>
                {anchor} <span> - {header}</span>
              </h2>
              <p>{subheader}</p>
            </div>
          </div>
        </div>
        <div className="feature-two-warp">
          <div className="row align-items-center">
            <div className="col-lg-4">
              <div className="feature-right-image">
                <img src="https://via.placeholder.com/770x485" alt="" />
              </div>
            </div>
            <div className="col-lg-8">
              <div className="feature-two-inner-wrap">
                {services.map(({ title, content, icon }, index) => (
                  <div className="single-feature" key={index}>
                    <div className="feature-icon">
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-3 w-3"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={1}
                            d={icon}
                          />
                        </svg>
                      </span>
                    </div>
                    <div className="feature-info">
                      <h3>{title}</h3>
                      <p>{content}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
